import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import auth from '../../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
        render={props => {
            if (!auth.isAuthed()) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                );
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return (<Component {...props} />);
        }
      }
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default PrivateRoute;
