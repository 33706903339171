import {
  CREATE_CLIENT_SUCCESS,
  FETCH_CLIENTS_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '../../actions/clients';

const initialState = {
  items: [],
  newClient: {},
};

export default function licensesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLIENTS_SUCCESS :
      return {
        ...state,
        items: action.payload.data,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        items: state.items.concat([action.payload.data]),
        newClient: action.payload.data,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => item.id === action.payload.data.id ? action.payload.data : item),
      };
    default:
      return state;
  }
}
