import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import i18nInit from '../../config/i18n';
import App from '../../components/App';
import ProjectsScreen from '../ProjectsScreen';
import GenerationsScreen from '../GenerationsScreen';
import LicensesScreen from '../LicensesScreen';
import AuthScreen from '../AuthScreen';
import PrivateRoute from '../../components/common/PrivateRoute';

class Root extends React.Component {
  constructor(props) {
    super(props);
    i18nInit('hr');
  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Suspense fallback={<div/>}>
                  <Route exact path="/:authType(login)" component={AuthScreen} />
                  <PrivateRoute exact path="/" component={ProjectsScreen} />
                  <PrivateRoute exact path="/licenses/:projectId" component={LicensesScreen} />
                  <PrivateRoute exact path="/licenses/:projectId/:generationId" component={LicensesScreen} />
                  <PrivateRoute exact path="/generations/:projectId" component={GenerationsScreen} />
                  <App />
              </Suspense>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
