import {
  LOGIN_SUCCESS, LOGOUT_SUCCESS,
} from '../../actions/auth';
import auth from '../../utils/auth';

const initialState = {
  isAuth: auth.isAuthed(),
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      auth.saveData(action.payload.data);
      return {
        ...state,
        isAuth: true,
        username: action.payload.data.username,
        role: action.payload.data.role,
      };
    case LOGOUT_SUCCESS:
      auth.removeData();
      return {
        isAuth: false,
      };
    default:
      return state;
  }
}
