import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Header, Button, Form, Modal, TextArea, List } from 'semantic-ui-react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {clearCreatedLicenses, createLicenses} from '../../actions/licenses';
import { UnixTimestampToString } from '../Tables/Common/TableCellFormatters';
import { common } from '../../utils';
import ClientModal, { CLIENT_MODAL_MODES } from '../ClientModal';
import { SearchableDropdown } from '../SearchableDropdown';

import './index.css';

class ProjectLicensesGenerationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.messageModal = withReactContent(Swal);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.newClient !== this.props.newClient) {
      const { clients, newClient } = this.props;
      const newClientValue = clients.find(c => c.id === newClient.id).id;

      this.setState(prevState => ({
        value: {
          ...prevState.value,
          clientId: newClientValue,
        }
      }));
    }
  };

  defaultState = () => ({
    value: {
      licensesNum: 1,
      licenseNumByPackage: '',
    },
    willHaveValidUntil: false,
    isClientModalOpen: false,
    clientModalMode: CLIENT_MODAL_MODES.ADD_CLIENT,
  });

  onClose = () => {
    const { dispatch, onClose } = this.props;
    dispatch(clearCreatedLicenses());
    this.setState(this.defaultState(), onClose);
  };

  switchWillHaveValidUntil = () => {
    this.setState(prevState => ({
      willHaveValidUntil: !prevState.willHaveValidUntil,
      value: {
        ...prevState.value,
        validUntil: null,
      }
    }));
  };

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      value: {
          ...prevState.value,
          [target.name]: target.value
        }
    }));
  };

  handleDropdownChange = (event, data) => {
    if(data.name === 'licenseTypeId'){
        const licenseNumber = data.options.filter(license => license.value === data.value).map(license => license.number);
        this.setState(prevState => ({
            value: {
                ...prevState.value,
                [data.name]: data.value,
                licenseNumByPackage: licenseNumber,
            }
        }));
    }
    this.setState(prevState => ({
      value: {
          ...prevState.value,
          [data.name]: data.value,
        }
    }));
  };

  submit = () => {
    const { dispatch, projectId, t, handleAddNewLicenses } = this.props;
    const { value } = this.state;

    if (!(value && value.licenseTypeId)) {
      common.renderMessageModal(
        this.messageModal,
        t('licenses.generation.missingLicenseType'),
        t('ok'),
      );
      return;
    }

    dispatch(
        createLicenses({
        ...value,
        projectId,
      })).then(handleAddNewLicenses);
  };

  renderClientModal = () => {
    const { clients } = this.props;
    const { isClientModalOpen, clientModalMode, value: { clientId } } = this.state;

    const client = clients.find(c => c.id === clientId);

    return (
      <ClientModal
        isModalOpen={isClientModalOpen}
        onClose={() => this.setState({ isClientModalOpen: false })}
        mode={clientModalMode}
        client={client}
      />
    );
  };

  onAddNewClient = () => {
    this.setState({
      isClientModalOpen: true,
      clientModalMode: CLIENT_MODAL_MODES.ADD_CLIENT,
    });
  };

  onEditClient = () => {
    this.setState({
      isClientModalOpen: true,
      clientModalMode: CLIENT_MODAL_MODES.EDIT_CLIENT,
    });
  };

  renderForm = () => {
    const { t, licenseTypes, clients, resellers } = this.props;
    const { willHaveValidUntil, value: { licensesNum, validUntil, licenseTypeId, clientId, resellerId, note, licenseNumByPackage } } = this.state;

    const clientsData = clients && clients.map(client => ({ key: client.id, value: client.id, text: client.name }));
    const resellersData = resellers && resellers.map(reseller => ({ key: reseller.id, value: reseller.id, text: reseller.name }));

    return (
      <Form name='licenseGenerationForm'>
        <Form.Field
          required
          name="licensesNum"
          label={t(`licenses.generation.licensesPackagesNumInfo`)}
          control='input'
          type='number'
          value={licensesNum || ''}
          onChange={this.handleChange}
          min={1}
        />
        <Form.Field>
          <label>{t(`licenses.generation.willHaveValidUntil`)}</label>
          <input type="checkbox" value={willHaveValidUntil} onClick={this.switchWillHaveValidUntil} className="hidden" readOnly="" tabIndex="0"/>
        </Form.Field>
        {willHaveValidUntil && <Form.Field
          required
          name="validUntil"
          label={t(`licenses.generation.validUntil`)}
          control='input'
          type='date'
          value={validUntil || ''}
          onChange={this.handleChange}
        />}
        <Form.Field>
          <label>{t(`licenses.generation.licenseTypeId`)}</label>
          <Form.Dropdown
            required
            name="licenseTypeId"
            placeholder={t(`licenses.generation.chooseLicenseType`)}
            fluid
            search
            selection
            options={licenseTypes}
            value={licenseTypeId || ''}
            onChange={this.handleDropdownChange}
          />
        </Form.Field>
        <Form.Input
            fluid
            className='numberOfLicensesByPackage'
            label={t(`licenses.generation.licensesNum`)}
            value={`${licenseNumByPackage} ${t(`licenses.generation.licensesByPackageInfo`)}` || ' '}
        />
        <Form.Field>
          <label>{t(`licenses.generation.client`)}</label>
          <SearchableDropdown
            placeholder={t(`licenses.generation.chooseClient`)}
            items={clientsData}
            onSelectionChange={this.handleDropdownChange}
            name="clientId"
            value={clientId || ''}
            onAddNewClick={this.onAddNewClient}
            onEditClick={clientId ? this.onEditClient: ''}
          />
        </Form.Field>
        <Form.Field>
          <label>{t(`licenses.generation.reseller`)}</label>
          <SearchableDropdown
            placeholder={t(`licenses.generation.chooseReseller`)}
            items={resellersData}
            onSelectionChange={this.handleDropdownChange}
            name="resellerId"
            value={resellerId|| ''}
          />
        </Form.Field>
        <Form.Field>
          <label>{t(`licenses.generation.note`)}</label>
          <TextArea
            name="note"
            value={note || ''}
            onChange={this.handleChange}
            rows={5}
          />
        </Form.Field>
        <Button className='green' onClick={this.submit}>{t(`licenses.generation.generate`)}</Button>
      </Form>
    );
  };

    renderCreatedLicenses = () => {
      const { createdLicenses, t } = this.props;
      const {value: {licensesNum, licenseNumByPackage}} = this.state;

      if(!createdLicenses) {
          return null;
      }

      const { serialKeys } = createdLicenses;
      const { licenseType, validUntil, client, reseller, note } = createdLicenses.generalInfo;

      const validUntilMessage = UnixTimestampToString(validUntil) || t(`licenses.generation.validForever`);
      const noteMessage = note || t(`licenses.generation.noNote`);

      return (
          <>
              <Header as='h2'>{t(`licenses.generation.generalInfo`)}</Header>
              <List bulleted>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.numOfGeneratedPackages`)} -</span>
                        <span className="generated-licenses-value">{licensesNum}</span>
                    </List.Item>
                    <List.Item>
                        <span className="generated-licenses-label">{t(`licenses.generation.numOfGeneratedLicensesByPackage`)} -</span>
                        <span className="generated-licenses-value">{licenseNumByPackage}</span>
                    </List.Item>
                    <List.Item>
                        <span className="generated-licenses-label">{t(`licenses.generation.licensesInTotal`)} -</span>
                      <span className="generated-licenses-value">{serialKeys.length}</span>
                  </List.Item>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.licenseTypeId`)}-</span>
                      <span className="generated-licenses-value">{licenseType}</span>
                  </List.Item>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.validUntil`)}-</span>
                      <span className="generated-licenses-value">{validUntilMessage}</span>
                  </List.Item>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.client`)}-</span>
                      <span className="generated-licenses-value">{client.name}</span>
                  </List.Item>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.reseller`)}-</span>
                      <span className="generated-licenses-value">{reseller.name}</span>
                  </List.Item>
                  <List.Item>
                      <span className="generated-licenses-label">{t(`licenses.generation.note`)}-</span>
                      <span className="generated-licenses-value">{noteMessage}</span>
                  </List.Item>
              </List>
              <Header as='h2'>{t(`licenses.generation.licenses`)}</Header>
              <List>
                  {createdLicenses.serialKeys.map((serialKey,index) => (index+1) % licenseNumByPackage === 0 ? (
                        <List.Item>{serialKey}<br/><br/></List.Item>
                        ) : (
                        <List.Item>{serialKey}</List.Item>
                        )
                    )}
            </List>
          </>
      );
    };

    renderModal = (title, contentFunc) => {
      const { isModalOpen } = this.props;
      return (
        <Modal
          open={isModalOpen}
          onClose={this.onClose}
          closeIcon
        >
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content image scrolling>
            <Modal.Content>
              {contentFunc()}
            </Modal.Content>
          </Modal.Content>
        </Modal>
      )
    };

    render() {
      const { t, createdLicenses } = this.props;

      const title = createdLicenses ? t(`licenses.generation.generatedLicenses`) : t(`licenses.generateLicenses`);
      const contentFunc = createdLicenses ? this.renderCreatedLicenses : this.renderForm;

      return (
        <>
          {this.renderClientModal()}
          {this.renderModal(title, contentFunc)}
        </>
      );
  };
}

ProjectLicensesGenerationModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  licenseTypes: state.licenses.licenseTypes,
  licenses: state.licenses.licenses,
  createdLicenses: state.licenses.createdLicenses,
  clients: state.clients.items,
  resellers: state.resellers.items,
  newClient: state.clients.newClient,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(ProjectLicensesGenerationModal);
