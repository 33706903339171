import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';

import { login } from '../../actions/auth';
import forms from './forms';

import './index.css';

class AuthScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    const { match: { params: { authType } } } = this.props;
    const { match: { params: { authType: nextAuthType } } } = nextProps;

    if (nextAuthType !== authType) {
      this.initializeState(nextProps.match.params.authType);
    }
  }

  initializeState = authType => {
    this.setState({ value: forms.data[authType] });
  };

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      value:
      {
        ...prevState.value,
        [target.name]: target.value
      }
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { authType } = this.props.match.params;
    const { value } = this.state;

    switch (authType) {
      case 'login':
        this.props.dispatch(login(value));
        break;

      default:
        break;
    }
  };

  redirectUser = () => {
    const { history, location: { state } } = this.props;
    const redirectRoute = state && state.from && state.from.pathname;

    history.replace(redirectRoute || '/');
  };

  renderInput = (field) => (
    <Form.Input
      fluid
      key={field.name}
      name={field.name}
      onChange={this.handleChange}
      placeholder={field.placeholder}
      type={field.type}
      icon={field.icon}
      iconPosition={field.iconPosition}
    />
  );

  renderForm = () => {
    const { match: { params } } = this.props;
    const inputs = forms.views[params.authType] || [];

    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
            E-Glas Licensing System
                    </Header>
          <Form size='large'>
            <Segment stacked>
              {inputs.map(this.renderInput)}
              <Button
                color='teal'
                size='large'
                fluid
                onClick={this.handleSubmit}
              >
                Login
                            </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    )
  };

  render() {
    const { isAuth } = this.props;

    if (isAuth) {
      this.redirectUser();
    }

    return (
      <>
        {this.renderForm()}
      </>
    );
  };
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
});

AuthScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      authType: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isAuth: PropTypes.bool.isRequired
};

export default compose(withTranslation('translation'), connect(mapStateToProps))(AuthScreen);
