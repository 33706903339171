import { Data } from 'react-data-grid-addons';
import COLUMNS_WITH_OBJECT_DATA from '../Constants';

export function translateColumn (rows, t, columnKey, translationPath) {
  return rows.map(row => ({...row, [columnKey]: t(`${translationPath}.${row[columnKey]}`)}));
};

export const onGridRowsUpdated = (fromRow, toRow, updated, tableRows) => {
  const rows = tableRows.slice();
  for (let i = fromRow; i <= toRow; i += 1) {
    rows[i] = { ...rows[i], ...updated };
  }
  return rows
};

export const onGridSort = (sortColumn, sortDirection, data) => {
  // eslint-disable-next-line consistent-return
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    }
    if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return (sortDirection === "NONE") ? data : [...data].sort(comparer);
};

export function getValidFilterValues(rows, columnId) {
  if (COLUMNS_WITH_OBJECT_DATA.includes(columnId)) {
    return rows
    .map(r => r[columnId].name)
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
  }
  return rows
    .map(r => r[columnId])
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
}

export const handleFilterChange = filter => filters => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

export function getRows(rows, filters) {
  const selectors = Data.Selectors;
  return selectors.getRows({ rows, filters });
}

export function translateColumnsHeaders(columnsToSet, t) {
  return columnsToSet.map(column => ({ ...column, name: t(column.name) }));
}

export function hideInvisibleColumns(columns) {
  return columns.filter(column => !(column.visible === false));
}