import React from 'react'
import { useSelector } from 'react-redux'
import { SearchableDropdown } from '../SearchableDropdown';

const formatClients = clients => clients.map(client => ({
  key: client.id,
  value: {
    id: client.id,
    name: client.name,
  },
  text: client.name,
}));

function ClientsDropdown({ onSelectionChange }) {
  const clients = useSelector(state => state.clients.items)
  const formatedClients = formatClients(clients);

  return (
    <SearchableDropdown
      items={formatedClients}
      onSelectionChange={onSelectionChange}
      name="clientId"
      placeholder="clients"
    />
  )
}

export default ClientsDropdown;
