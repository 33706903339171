import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { SearchableDropdown } from '../../../SearchableDropdown';

class ResellersEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedReseller: props.value };
    }

    getValue() {
        const { selectedReseller } = this.state;
        return { reseller: selectedReseller };
    }

    getInputNode() {
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }

    handleDropdownChange = (event, data) => {
        this.setState({ selectedReseller: data.value }, () => this.props.onCommit());
    };

    render() {
        const { resellers } = this.props;

        return (
            <SearchableDropdown
                items={resellers}
                onSelectionChange={this.handleDropdownChange}
                name="resellerId"
            />
        );
    };
}

ResellersEditor.propTypes = {
    resellers: PropTypes.array.isRequired,
};

export default ResellersEditor;