import { combineReducers } from 'redux';
import projectsReducer from './projects/projectsReducer';
import authReducer from './auth/authReducer';
import appReducer from './app/appReducer';
import licensesReducer from './licenses/licensesReducer';
import clientsReducer from './clients/clientsReducer';
import generationsReducer from './generations/generationsReducer';
import resellersReducer from './resellers/resellersReducer';

const rootReducer = combineReducers({
  app: appReducer,
  licenses: licensesReducer,
  projects: projectsReducer,
  auth: authReducer,
  clients: clientsReducer,
  generations: generationsReducer,
  resellers: resellersReducer,
});

export default rootReducer;
