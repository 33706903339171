import React from 'react';
import { useTranslation } from 'react-i18next';

function filterValues(row, columnFilter, columnKey) {
  if (columnFilter.filterTerm === null) {
    return true;
  }
  if (row[columnKey].name === null) {
    return false;
  }
  return row[columnKey].name.toLowerCase().includes(columnFilter.filterTerm.toLowerCase());
}

export default function ObjectNameFilters({column, onChange}) {
  const { t } = useTranslation();
  return (
      <input
        placeholder={t('search')}
        type="text"
        onChange={e => {onChange({ filterTerm: e.target.value, column, rawValue: e.target.value, filterValues })}}
      />
  );
}
