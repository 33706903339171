import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

function NavButton({path='/', text='', icon='backward'}) {
  const history = useHistory();

  function handleClick() {
    history.push(path);
  }

  return (
    <Button color='green' icon={icon} labelPosition='left' onClick={handleClick} content={text}/>
  );
}

export default NavButton;