import network from '../../utils/network';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';
import API_SETTINGS from '../../constants/api';

export const FETCH_LICENSES_FROM_PROJECT_SUCCESS = 'FETCH_LICENSES_FROM_PROJECT_SUCCESS';
export const FETCH_LICENSES_FROM_GENERATION_SUCCESS = 'FETCH_LICENSES_FROM_GENERATION_SUCCESS';
export const FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS = 'FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS';
export const CREATE_LICENSES_SUCCESS = 'CREATE_LICENSES_SUCCESS';
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS';
export const RESET_LICENSE_SUCCESS = 'RESET_LICENSE_SUCCESS';
export const CLEAR_CREATED_LICENSES = 'CLEAR_CREATED_LICENSES';

export const fetchLicensesFromProject = projectId =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licenses/${projectId}`,
        {
            begin: ACTION_BEGIN,
            success: FETCH_LICENSES_FROM_PROJECT_SUCCESS,
            failure: ACTION_FAILURE,
        }
    );

export const fetchLicensesFromGeneration = generationId =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licenses/licensegeneration/${generationId}`,
        {
            begin: ACTION_BEGIN,
            success: FETCH_LICENSES_FROM_GENERATION_SUCCESS,
            failure: ACTION_FAILURE,
        }
    );

export const fetchLicenseTypesFromProject = projectId =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licensetypes/${projectId}`,
        {
            begin: ACTION_BEGIN,
            success: FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS,
            failure: ACTION_FAILURE,
        }
    );

export const createLicenses = data =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licenses/create`,
        {
            begin: ACTION_BEGIN,
            success: CREATE_LICENSES_SUCCESS,
            failure: ACTION_FAILURE,
        },
        {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        }
    );

export const updateLicense = (licenseId, jsonPatchBody) =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licenses/${licenseId}`,
        {
            begin: ACTION_BEGIN,
            success: UPDATE_LICENSE_SUCCESS,
            failure: ACTION_FAILURE,
        },
        {
            method: 'PATCH',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(jsonPatchBody)
        }
    );

export const resetLicense = licenseId =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/licenses/reset/${licenseId}`,
        {
          begin: ACTION_BEGIN,
          success: RESET_LICENSE_SUCCESS,
          failure: ACTION_FAILURE,
        },
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
        }
    );

export const clearCreatedLicenses = () =>
    dispatch => {
        dispatch({
            type: CLEAR_CREATED_LICENSES
        });
    };

