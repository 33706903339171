import { FETCH_GENERATIONS_FROM_PROJECT_SUCCESS } from '../../actions/generations';

const initialState = {
    generations: [],
};

export default function generationsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GENERATIONS_FROM_PROJECT_SUCCESS :
            return {
                ...state,
                generations: action.payload.data,
            };
        default:
            return state;
    }
}
