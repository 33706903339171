import network from '../../utils/network';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';
import API_SETTINGS from '../../constants/api';

export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';

export const fetchProjects = () => {
  return network.actionWrapper(
    `${API_SETTINGS.PREFIX}/projects`, {
      begin: ACTION_BEGIN,
      success: FETCH_PROJECTS_SUCCESS,
      failure: ACTION_FAILURE
    });
};
