import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import generationsColumns from './tableConfig';
import NavButton from '../../NavButton';
import config from '../../../config';
import { getValidFilterValues, handleFilterChange, getRows, translateColumnsHeaders } from '../Common/Functions';
import TableToolbar from '../Common/TableToolbar';

const RowRenderer = ({ renderBaseRow, ...props }, projectId) => {
  const { t } = useTranslation();
  const rowId = props.row.id;
  const newProps = {...props};
  newProps.row.buttons =
    <>
      <NavButton text={t(`licenses.button.viewLicenses`)} path={`/licenses/${projectId}/${rowId}`} icon='table' />
      <a key={`${rowId}`} href={`${config.apiUrl}/api/licenseGeneration/excel/${rowId}`}>
        <Button color='green' content={t('licenses.button.downloadExcel')} />
      </a>
    </>;
  return renderBaseRow(newProps);
};

function GenerationsTable({ rows, onGridSort, projectId }) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const setColumns = translateColumnsHeaders(generationsColumns, t);
  const filteredRows = getRows(rows, filters);

  return (
    <>
      <h2>{t('licenses.generation.generationsTable')}</h2>
      <ReactDataGrid
        columns={setColumns}
        width="12.5%"
        rowGetter={i => filteredRows[i]}
        rowsCount={filteredRows.length}
        minHeight={500}
        rowHeight={50}
        onGridSort={onGridSort}
        onAddFilter={filter => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
        rowRenderer={props => RowRenderer(props, projectId)}
        toolbar={TableToolbar}
      />
    </>
  );
}

GenerationsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  onGridSort: PropTypes.func,
};

export default GenerationsTable;
