import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import Root from './containers/Root';
import RootReducer from './reducers'
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';

const middlewares = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
}

const store = createStore(
  RootReducer,
  applyMiddleware(...middlewares)
);

function init() {
  ReactDOM.render(<Root store={store} />, document.getElementById('root'));
  registerServiceWorker();
}

init();
