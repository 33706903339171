import React from "react";
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import config from '../../../../config';

export const UnixTimestampToString = timestamp => {
  return timestamp && moment.unix(timestamp).utc().format('DD.MM.YYYY. HH:mm:ss');
};

export const DateTimeFormatter = ({ value }) => {
  return <span>{UnixTimestampToString(value)}</span>;
};

export const NameFormatter = ({ value }) => {
  return <span>{value.name}</span>;
};

export const IsDisabledFormatter = ({ value }) => {
  const { t } = useTranslation();
  const boolTranslation = value ? t('yes') : t('no');
  return <span>{boolTranslation}</span>
};

export const GeneratePDFButtonFormatter = ({ row }) => {
  const { id, licensePdfLangs, serialKey } = row;
  return (
    licensePdfLangs.map(lang =>
      <a key={`${id}${lang}`} href={`${config.apiUrl}/api/licenses/pdf/${serialKey}/${lang}`}>
        <Button color='green' content={lang} />
      </a>)
  );
};

export const EditableHeaderMarker = ({ column }) => {
  return <label className="column-header">{column.name}<Icon name='edit' /></label>;
};
