import {
  DateTimeFormatter,
  NameFormatter,
} from '../Common/TableCellFormatters';
import ObjectNameFilter from '../Common/Filters/ObjectNameFilter';

const defaultColumnProperties = {
  resizable: true,
};

const generationsColumns = [
  {
    key: 'id',
    name: 'licenses.columns.id',
    width: 75,
    filterable: true,
    sortable: true,
  },
  {
    key: 'licenseType',
    name: 'licenses.columns.licenseType',
    width: 100,
    filterable: true,
    sortable: true,
    formatter: NameFormatter,
    filterRenderer: ObjectNameFilter,
  },
  {
    key: 'packagesNum',
    name: 'licenses.generation.numOfGeneratedPackages',
    width: 150,
    filterable: true,
    sortable: true,
  },
  {
    key: 'createdOn',
    name: 'licenses.columns.createdOn',
    width: 200,
    formatter: DateTimeFormatter,
    sortable: true,
  },
  {
    key: 'validUntil',
    name: 'licenses.columns.validUntil',
    width: 200,
    formatter: DateTimeFormatter,
    sortable: true,
  },
  {
    key: 'client',
    name: 'licenses.columns.client',
    width: 125,
    filterable: true,
    sortable: true,
    formatter: NameFormatter,
    filterRenderer: ObjectNameFilter,
  },
  {
    key: 'reseller',
    name: 'licenses.columns.reseller',
    width: 125,
    filterable: true,
    sortable: true,
    formatter: NameFormatter,
    filterRenderer: ObjectNameFilter,
  },
  {
    key: 'note',
    name: 'licenses.columns.note',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    key: 'creator',
    name: 'licenses.columns.creator',
    width: 125,
    filterable: true,
    editable: false,
    formatter: NameFormatter,
    filterRenderer: ObjectNameFilter,
  },
  {
    key: 'buttons',
    name: 'licenses.columns.actions',
    width: 300,
    filterable: false,
    sortable: false,
  },
].map(c => ({ ...c, ...defaultColumnProperties }));

export default generationsColumns;
