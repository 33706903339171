import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import licensesColumns from './tableConfig';
import ResellersEditor from '../Common/TableCellEditors/ResellersEditor';
import IsDisabledEditor from '../Common/TableCellEditors/IsDisabledEditor';
import LicenseEditToolbar from './tableToolbar';
import { getValidFilterValues, translateColumn, handleFilterChange, getRows, translateColumnsHeaders, hideInvisibleColumns } from '../Common/Functions';
import auth from '../../../utils/auth';
import ROLES from '../../../constants/roles';

function ResellerEditorWrapper(resellers) {
  return (
    <ResellersEditor resellers={resellers} />
  );
}

function IsDisabledEditorWrapper(choices) {
  return (
    <IsDisabledEditor choices={choices} />
  );
}

function AddResellerEditor(columnsToSet, resellers) {
  const resellersData = resellers.map(reseller => ({
    key: reseller.id,
    value: {
      id: reseller.id,
      name: reseller.name,
    },
    text: reseller.name,
  }));

  return columnsToSet.map(column => column.key.toString() === 'reseller' ? ({...column, editor: ResellerEditorWrapper(resellersData)}): column);
}

function AddIsDisabledEditor(columnsToSet) {
  const { t } = useTranslation();

  const yesChoice = {
    key: 'yes',
    value: true,
    text: t('yes'),
  };

  const noChoice = {
    key: 'no',
    value: false,
    text: t('no'),
  };

  const choices = [yesChoice, noChoice];

  return columnsToSet.map(column => column.key.toString() === 'isDisabled' ? ({...column, editor: IsDisabledEditorWrapper(choices)}): column);
}

function AddEditors(resellers, translatedColumns) {
  let setColumns = [...translatedColumns];

  if (resellers && resellers.length){
    setColumns = AddResellerEditor(setColumns, resellers);
  }

  setColumns = AddIsDisabledEditor(setColumns);

  return setColumns;
}
const RowRenderer = ({ renderBaseRow, ...rest }, isFiltered, sortDirection) => {
  if (isFiltered || (sortDirection && sortDirection !== 'UNSORT') ) {
    return renderBaseRow(rest)
  }
  return <div className={rest.row.rowColor}>{renderBaseRow(rest)}</div>
}; 

let prevGroup = 0;
let className = '';
const setRowColor = id => {
  if (prevGroup !== id) {
    prevGroup = id;
    if (className === '') {
      className = 'gray-row'
    } else {
      className = ''
    }
  }
  return className;
};

function canResetLicense(licenseStatus, t) {
  return auth.isRole([ROLES.ADMIN_ROLE, ROLES.EDIT_ROLE])
      && licenseStatus === t('licenses.status.Activated');
}

function getCellActions(column, row, onLicenseReset, t) {
  const cellActions = {
    id: [{
      icon: <Icon name="repeat"/>,
      callback: () => onLicenseReset(column, row),
      show: canResetLicense(row.status, t),
    }]
  };

  if (!(column.key in cellActions)) {
    return null;
  }

  const columnCellActions = cellActions[column.key];
  return columnCellActions.filter(cellAction => cellAction.show);
}

function getCellActionsWrapper(onLicenseReset, t) {
  return (rows, columns) => getCellActions(rows, columns, onLicenseReset, t)
}

function RenderToolbar(onToggleFilter, isDataFetched, toggleTableEdit, licenseChangeExists, handleLicenseSave, handleSaveLicenseChanges, revertLicensesChanges, licenseTableEditable, removeSort) {
  return (
    <LicenseEditToolbar
      onToggleFilter={onToggleFilter}
      isLicenseDataFetched={isDataFetched}
      licenseChangeExists={licenseChangeExists}
      handleLicenseSave={handleLicenseSave}
      revertLicensesChanges={revertLicensesChanges}
      licenseTableEditable={licenseTableEditable}
      toggleTableEdit={toggleTableEdit}
      handleSaveLicenseChanges={handleSaveLicenseChanges}
      removeSort={removeSort}
    />
  );
}

function LicensesTable({
   rows,
   onGridRowsUpdated,
   onGridSort,
   isDataFetched,
   licenseChangeExists,
   handleLicenseSave,
   handleSaveLicenseChanges,
   revertLicensesChanges,
   toggleTableEdit,
   licenseTableEditable,
   resellers,
   setCurrentSortDirection,
   onLicenseReset,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [currentSortDir, setCurrentSortDir] = useState(null);
  const gridRef = useRef(null);
  
  useEffect(() => {
    setCurrentSortDirection(currentSortDir)
  }, [currentSortDir, setCurrentSortDirection]);

  let columns = translateColumnsHeaders(licensesColumns, t);
  columns = hideInvisibleColumns(columns);
  columns = AddEditors(resellers, columns);

  let translatedRows = translateColumn(rows, t, 'status', 'licenses.status');
  translatedRows = translatedRows.map(row => ({...row, rowColor: setRowColor(row.licenseGroupId)})); 
  
  let removeSort = () => {};
  if (gridRef.current) {
    removeSort = () => gridRef.current.handleSort(null, "UNSORT");
  }

  const filteredRows = getRows(translatedRows, filters);
  const toolbar = onToggleFilter => RenderToolbar(onToggleFilter, isDataFetched, toggleTableEdit, licenseChangeExists, handleLicenseSave, handleSaveLicenseChanges, revertLicensesChanges, licenseTableEditable, removeSort);

  return (
    <>
      <h2>{t('licenses.licensesTable')}</h2>
      <ReactDataGrid
        ref={gridRef}
        columns={columns}
        enableCellSelect={licenseTableEditable}
        width="12.5%"
        rowGetter={i => filteredRows[i]}
        rowsCount={filteredRows.length}
        minHeight={500}
        rowHeight={50}
        onGridSort={(sortColumn, sortDirection, data) => {onGridSort(sortColumn, sortDirection, data); setCurrentSortDir(gridRef.current.state.sortDirection)}}
        onGridRowsUpdated={onGridRowsUpdated}
        toolbar={props => toolbar(props.onToggleFilter)}
        onAddFilter={props => {setIsFiltered(true); setFilters(handleFilterChange(props))}}
        onClearFilters={() => {setIsFiltered(false); setFilters({})}}
        toggleTableEdit={toggleTableEdit}
        getValidFilterValues={columnKey => getValidFilterValues(translatedRows, columnKey)}
        rowRenderer={props => RowRenderer(props, isFiltered, currentSortDir)}
        getCellActions={getCellActionsWrapper(onLicenseReset, t)}
      />
    </>
  );
}

LicensesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  resellers: PropTypes.array.isRequired,
  onGridRowsUpdated: PropTypes.func.isRequired,
  onGridSort: PropTypes.func,
  setCurrentSortDirection: PropTypes.func.isRequired,
  onLicenseReset: PropTypes.func,
};

export default LicensesTable;
