import network from '../../utils/network';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';
import API_SETTINGS from '../../constants/api';

export const FETCH_RESELLER_SUCCESS = 'FETCH_RESELLER_SUCCESS';

export const fetchResellers = () =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/resellers`,
        {
            begin: ACTION_BEGIN,
            success: FETCH_RESELLER_SUCCESS,
            failure: ACTION_FAILURE,
        }
    );
