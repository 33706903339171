import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'semantic-ui-react';
import moment from 'moment';

class ValidUntilEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedValidUntil: props.value };
  }

  getValue() {
    const { selectedValidUntil } = this.state;
    return { validUntil: selectedValidUntil };
  }

  getInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  handleChange = ({ target }) => {
    const unixTimestamp = target.value ?
      moment.utc(target.value, 'YYYY-MM-DD').unix() :
      moment().unix();

    this.setState({
      selectedValidUntil: unixTimestamp,
    });
  };

  render() {
    const { selectedValidUntil } = this.state;
    const dateFormValue = selectedValidUntil ?
      moment.unix(selectedValidUntil).utc().format('YYYY-MM-DD') :
      moment().format('YYYY-MM-DD');

    return (
      <Form.Field
        name="validUntil"
        control='input'
        type='date'
        value={dateFormValue}
        onChange={this.handleChange}
      />
    );
  };
}

export default ValidUntilEditor;