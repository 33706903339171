import { FETCH_RESELLER_SUCCESS } from '../../actions/resellers';

const initialState = {
    items: [],
};

export default function licensesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_RESELLER_SUCCESS :
            return {
                ...state,
                items: action.payload.data,
            };
        default:
            return state;
    }
}
