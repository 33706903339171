import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Segment } from 'semantic-ui-react';

export class SearchableDropdown extends React.PureComponent {
  render() {
    const { placeholder, items, onAddNewClick, onEditClick, onSelectionChange, name, value } = this.props;

    return (
      <Segment compact style={{ marginTop: '0' }}>
        <Dropdown
          selection
          options={items}
          search
          clearable
          name={name}
          value={value}
          onChange={onSelectionChange}
          placeholder={placeholder}
        />
        <Button.Group basic style={{ marginLeft: '10px' }}>
          {onAddNewClick && <Button onClick={onAddNewClick} icon="add" />}
          {onEditClick && <Button onClick={onEditClick} icon="edit" />}
        </Button.Group>
      </Segment>
    );
  }
}

SearchableDropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  items: PropTypes.array.isRequired,
  onAddNewClick: PropTypes.func,
  onEditClick:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onSelectionChange: PropTypes.func,
};

export default SearchableDropdown;