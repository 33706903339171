import network from '../../utils/network';
import API_SETTINGS from '../../constants/api';
import {ACTION_BEGIN, ACTION_FAILURE} from '../app';

export const FETCH_GENERATIONS_FROM_PROJECT_SUCCESS = 'FETCH_GENERATIONS_FROM_PROJECT_SUCCESS';

export const fetchGenerationsFromProject = projectId =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/LicenseGeneration/${projectId}`,
        {
            begin: ACTION_BEGIN,
            success: FETCH_GENERATIONS_FROM_PROJECT_SUCCESS,
            failure: ACTION_FAILURE,
        }
    );
