import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid, Header, Button, Divider } from 'semantic-ui-react';
import path from 'path';
import { compare } from 'fast-json-patch';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LicensesTable from '../../components/Tables/LicensesTable';
import ProjectLicensesGenerationModal from '../../components/ProjectLicensesGenerationModal';
import ClientsModal from '../../components/ClientsModal';
import NavButton from '../../components/NavButton';
import {
  fetchLicensesFromProject,
  fetchLicensesFromGeneration,
  fetchLicenseTypesFromProject,
  updateLicense,
  resetLicense
} from '../../actions/licenses';
import { fetchClients } from '../../actions/clients';
import { fetchResellers } from '../../actions/resellers';
import { fetchProjects } from '../../actions/projects';
import { logout } from '../../actions/auth';
import { onGridSort, onGridRowsUpdated } from '../../components/Tables/Common/Functions';
import { common } from '../../utils';
import auth from '../../utils/auth';
import roles from '../../constants/roles';
import { ACTION_FAILURE } from '../../actions/app';

class LicensesScreen extends React.Component {
  constructor(props) {
    super(props);

    this.messageModal = withReactContent(Swal);
    this.state = {
      isNewLicenseModalOpen: false,
      isRenderClientsModalOpen: false,
      selectedProjectName: null,
      isLicensesFetched: false,
      licenseTableEditable: false,
      licenses: [],
      editedLicenses: [],
      currentSortDirection: null,
    };
  };

  componentDidMount() {
    const { match: { params: { projectId } }, dispatch } = this.props;

    this.fetchLicenses();
    dispatch(fetchLicenseTypesFromProject(projectId));
    dispatch(fetchClients());
    dispatch(fetchResellers());
    dispatch(fetchProjects())
      .then(projects => {
        this.setState({ selectedProjectName: common.getProjectName(projects, projectId) });
      })
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    const { match: { params: { generationId } }, dispatch, initialLicenses } = this.props;

    if (prevProps.initialLicenses !== initialLicenses) {
      this.setState({
        licenses: initialLicenses,
        editedLicenses: [],
      });
    }

    if (generationId && prevProps.match.params.generationId !== generationId) {
      dispatch(fetchLicensesFromGeneration(generationId)).then(() => {
        this.setState({ isLicensesFetched: true });
      });
    }
  }

  fetchLicenses = () => {
    const { match: { params: { projectId, generationId } }, dispatch } = this.props;

    if (!generationId) {
      this.setState({ isLicensesFetched: false }, () => {
        dispatch(fetchLicensesFromProject(projectId)).then(() => {
          this.setState({ isLicensesFetched: true });
        })
      });
    } else {
      this.setState({ isLicensesFetched: false }, () => {
        dispatch(fetchLicensesFromGeneration(generationId)).then(() => {
          this.setState({ isLicensesFetched: true });
        });
      })
    }
  };

  setCurrentSortDirection = direction => {
    this.setState({
      currentSortDirection: direction
    });
  };

  toggleTableEdit = () => {
    this.setState(state => ({
      licenseTableEditable: !state.licenseTableEditable
    }));
  };

  logout = () => {
    const { dispatch, history } = this.props;
    dispatch(logout())
      .then(() => {
        history.replace('/');
      });
  };

  revertLicensesChanges = () => {
    const { editedLicenses } = this.state;
    if (editedLicenses && editedLicenses.length) {
      this.setState({
        editedLicenses: [],
      });
    }
  };

  transformJsonPatch = jsonPatchArray => {
    const { licenses } = this.state;

    const pathMap = {
      "client/id": "clientId",
      "reseller/id": "resellerId",
      "validUntil": "validUntil",
      "note": "note",
      "isDisabled": "isDisabled",
    };

    const valueTransformMap = (apiPath, value) => {
      switch (apiPath) {
        case "validUntil":
          return moment.unix(value).utc().format('YYYY-MM-DD');
        default:
          return value;
      }
    };

    const arrayOfRequests = {};
    jsonPatchArray.forEach(jsonPatch => {
      const splitPath = jsonPatch.path.split(path.sep);
      const rowId = splitPath[1];
      const pathElements = splitPath.slice(2);
      const restOfPath = path.join(...pathElements);
      const licenseId = licenses[rowId].id;

      if (!(restOfPath in pathMap)) {
        return;
      }

      const apiPath = pathMap[restOfPath];
      const transformedJsonPatch = {
        op: jsonPatch.op,
        path: apiPath,
        value: valueTransformMap(apiPath, jsonPatch.value),
      };

      if (licenseId in arrayOfRequests) {
        arrayOfRequests[licenseId].push(transformedJsonPatch);
      }
      else {
        arrayOfRequests[licenseId] = [transformedJsonPatch];
      }
    });

    return arrayOfRequests;
  };

  fetchLicensesOnEdit = () => {
    const { match: { params: { projectId, generationId } }, dispatch } = this.props;

    if (!generationId) {
      this.setState({ isLicensesFetched: false }, () => {
        dispatch(fetchLicensesFromProject(projectId)).then(() => {
          this.setState({ isLicensesFetched: true });
        })
      });
    } else {
      this.setState({ isLicensesFetched: false }, () => {
        dispatch(fetchLicensesFromGeneration(generationId)).then(() => {
          this.setState({ isLicensesFetched: true });
        });
      })
    }
  };

  updateLicenses = () => {
    const { dispatch } = this.props;
    const { licenses, editedLicenses } = this.state;

    const editedValues = compare(licenses, editedLicenses);
    const arrayOfRequests = this.transformJsonPatch(editedValues);

    Promise.all(Object.entries(arrayOfRequests).map(([key, value]) => dispatch(updateLicense(key, value))))
      .then((values) => {
        const { t } = this.props;
        const hasFailures = !!values.filter(val => val.type === ACTION_FAILURE).length;
        if (hasFailures) {
          Swal.fire({
            type: 'error',
            title: t(`modal.title.error`),
            text: t(`modal.message.error.editLicensesError`),
            confirmButtonText: t(`ok`),
            confirmButtonColor: '#21BA45',
            showCancelButton: false,
          })
        } else {
          this.setState({
            licenses: editedLicenses,
            editedLicenses: [],
          }, this.fetchLicensesOnEdit)
        }
      });
  };

  handleSaveLicenseChanges = () => {
    this.updateLicenses()
  };

  renderResetConfirmation = licenseId => {
    const { t, dispatch } = this.props;

    this.messageModal.fire({
      title: t('licenses.resetLicenseConfirmation'),
      onClose: this.closeMessageModal,
      confirmButtonColor: '#AF6098',
      showConfirmButton: true,
      confirmButtonText: t('yes'),
      showCancelButton: true,
      cancelButtonText: t('no'),
    })
    .then(result => {
      if (result.value) {
        dispatch(resetLicense(licenseId)).then(() => {
          this.fetchLicenses();
        })
      }
    })
  };

  resetLicenseAction = (column, row) => {
    this.renderResetConfirmation(row.id);
  };

  renderTop = () => {
    const { match: { params: { projectId } }, t } = this.props;
    const { selectedProjectName } = this.state;

    return (
      <Grid textAlign="center">
        <Grid.Column width={4}>
          <NavButton text={t('projects')} />
          <NavButton text={t(`licenses.generation.generations`)} path={`/generations/${projectId}`} icon='table' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h1'>{selectedProjectName}</Header>
        </Grid.Column>
        <Grid.Column width={4}>
          {auth.isRole([roles.ADMIN_ROLE]) &&
            <Button color='green' icon='add' labelPosition='left' onClick={() => this.setState({ isNewLicenseModalOpen: true })} content={t('licenses.generateLicenses')} />
          }
          {auth.isRole([roles.EDIT_ROLE, roles.ADMIN_ROLE]) &&
            <Button color='green' icon='users' labelPosition='left' onClick={() => this.setState({isRenderClientsModalOpen: true})} content={t('licenses.clients')}/>
          }
          <Button color='green' icon='log out' labelPosition='left' onClick={this.logout} content={t('licenses.logout')} />
        </Grid.Column>
      </Grid>
    );
  };

  renderClientsModal = () => {
    const { isRenderClientsModalOpen } = this.state;
    return (
      <ClientsModal
        isModalOpen={isRenderClientsModalOpen}
        onClose={() => this.setState({ isRenderClientsModalOpen: false })}
      />
    );
  };

  renderNewLicensesModal = () => {
    const { match: { params: { projectId } } } = this.props;
    const { isNewLicenseModalOpen } = this.state;

    return (
      <ProjectLicensesGenerationModal
        projectId={projectId}
        isModalOpen={isNewLicenseModalOpen}
        onClose={() => this.setState({ isNewLicenseModalOpen: false })}
        handleAddNewLicenses={this.fetchLicensesOnEdit}
      />
    );
  };

  getTableRows = () => {
    const { initialLicenses } = this.props;
    const { licenses, editedLicenses, currentSortDirection } = this.state;

    const editedLicensesExist = editedLicenses && editedLicenses.length;

    let tableRows = editedLicensesExist ? editedLicenses : licenses;
    tableRows = (currentSortDirection === "UNSORT" && !editedLicensesExist) ? initialLicenses : tableRows;

    return tableRows;
  };

  renderDataTable = () => {
    const { resellers, licenseTypes } = this.props;
    const { licenses, editedLicenses, isLicensesFetched, licenseTableEditable } = this.state;

    const tableRows = this.getTableRows();
    const licenseChangeExists = (editedLicenses && editedLicenses.length > 0) && (JSON.stringify(editedLicenses) !== JSON.stringify(licenses));

    return (
      <Grid textAlign="center" >
        <Grid.Column width={15}>
          <LicensesTable
            setCurrentSortDirection={this.setCurrentSortDirection}
            rows={tableRows}
            onGridRowsUpdated={({ fromRow, toRow, updated }) => this.setState({ editedLicenses: onGridRowsUpdated(fromRow, toRow, updated, tableRows) })}
            isDataFetched={isLicensesFetched}
            licenseChangeExists={licenseChangeExists}
            resellers={resellers}
            licenseTypes={licenseTypes}
            revertLicensesChanges={this.revertLicensesChanges}
            licenseTableEditable={licenseTableEditable}
            toggleTableEdit={this.toggleTableEdit}
            handleSaveLicenseChanges={this.handleSaveLicenseChanges}
            onLicenseReset={this.resetLicenseAction}
            onGridSort={(sortColumn, sortDirection) =>
              this.setState(prevState =>
                ({
                  licenses: onGridSort(sortColumn, sortDirection, prevState.licenses),
                  editedLicenses: [],
                })
              )}
          />
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    return (
      <>
        <Divider hidden />
        {this.renderTop()}
        {this.renderNewLicensesModal()}
        {this.renderClientsModal()}
        <Divider hidden />
        {this.renderDataTable()}
      </>
    );
  };
}

const mapStateToProps = state => ({
  initialLicenses: state.licenses.licenses,
  projects: state.projects.items,
  resellers: state.resellers.items,
  newLicenses: state.licenses.newLicenses,
  licenseTypes: state.licenses.licenseTypes,
  message: state.licenses.message,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(LicensesScreen);
