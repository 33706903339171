import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

function i18nInit(lng) {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng,
            backend: {
                loadPath: 'locales/{{lng}}/{{ns}}.json'
            },
            fallbackLng: 'hr',
            debug: (process.env.REACT_APP_ENV || 'development') === 'development',
            interpolation: {
                escapeValue: false,
            },
            react: {
                wait: true,
            }
        });
}

export default i18nInit;