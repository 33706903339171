import network from '../../utils/network';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';
import API_SETTINGS from '../../constants/api';

export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';

export const fetchClients = () =>
  network.actionWrapper(
    `${API_SETTINGS.PREFIX}/clients`,
    {
      begin: ACTION_BEGIN,
      success: FETCH_CLIENTS_SUCCESS,
      failure: ACTION_FAILURE,
    }
  );

export const createClient = client =>
  network.actionWrapper(
   `${API_SETTINGS.PREFIX}/clients`,
   {
      begin: ACTION_BEGIN,
      success: CREATE_CLIENT_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(client)
    }
  );

export const updateClient = (clientId, jsonPatchBody) =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/clients/${clientId}`,
        {
          begin: ACTION_BEGIN,
          success: UPDATE_CLIENT_SUCCESS,
          failure: ACTION_FAILURE,
        },
        {
          method: 'PATCH',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(jsonPatchBody)
        }
    );
