import React from 'react';
import ReactDOM from 'react-dom';
import { TextArea } from 'semantic-ui-react';

class NoteEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { newNote: props.value };
    }

    getValue() {
        const { newNote } = this.state;
        return { note: newNote };
    }

    getInputNode() {
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }

    handleChange = ({ target }) => {
        this.setState({
            newNote: target.value,
        });
    };

    render() {
        const { newNote } = this.state;

        return (
            <TextArea
                name="note"
                value={newNote || ""}
                onChange={this.handleChange}
                rows={5}
            />
        );
    };
}

export default NoteEditor;