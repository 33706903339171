import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid, Header, Button, Divider } from 'semantic-ui-react';

import GenerationsTable from '../../components/Tables/GenerationsTable';
import NavButton from '../../components/NavButton';
import { fetchProjects } from '../../actions/projects';
import { fetchGenerationsFromProject } from '../../actions/generations';
import { logout } from '../../actions/auth';
import { common } from '../../utils';
import { onGridSort } from '../../components/Tables/Common/Functions';

export class GenerationsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjectName: null,
      generations: []
    };
  };

  componentDidMount() {
    const { match: { params: { projectId } }, dispatch } = this.props;

    dispatch(fetchGenerationsFromProject(projectId));
    dispatch(fetchProjects())
      .then(projects => {
        this.setState({ selectedProjectName: common.getProjectName(projects, projectId) });
      })
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    const { match: { params: { projectId } }, dispatch, initialGenerations } = this.props;

    if (prevProps.initialGenerations !== initialGenerations) {
      this.setState({
        generations: initialGenerations,
      });
    }

    if (projectId && prevProps.match.params.projectId !== projectId) {
      dispatch(fetchGenerationsFromProject(projectId));
    }
  }

  logout = () => {
    const { dispatch, history } = this.props;
    dispatch(logout())
      .then(() => {
        history.replace('/');
      });
  };

  renderTop = () => {
    const { t, match: { params: { projectId } } } = this.props;
    const { selectedProjectName } = this.state;
    
    return (
      <Grid textAlign="center">
        <Grid.Column width={4}>
          <NavButton text={t('projects')} />
          <NavButton text={t('licenses.licensesTable')} path={`/licenses/${projectId}`} icon='table' />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h1'>{selectedProjectName}</Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Button color='green' icon='log out' labelPosition='left' onClick={this.logout} content={t('licenses.logout')} />
        </Grid.Column>
      </Grid>
    );
  };
  
  renderDataTable = () => {
    const { generations } = this.state;
    const { match: { params: { projectId } } } = this.props;
    return (
      <Grid textAlign="center" >
        <Grid.Column width={15}>
          <GenerationsTable
            rows={generations}
            onGridSort={(sortColumn, sortDirection) =>
              this.setState(prevState =>
                ({ generations: onGridSort(sortColumn, sortDirection, prevState.generations) })
              )}
            projectId={projectId}
          />
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    return (
      <>
        <Divider hidden />
        {this.renderTop()}
        <Divider hidden />
        {this.renderDataTable()}
      </>
    );
  };
}

const mapStateToProps = state => ({
  initialGenerations: state.generations.generations,
  projects: state.projects.items,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(GenerationsScreen);
