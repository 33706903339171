import React from 'react';
import { Filters } from 'react-data-grid-addons';

import ValidUntilEditor from '../Common/TableCellEditors/ValidUntilEditor';
import NoteEditor from '../Common/TableCellEditors/NoteEditor';
import IsDisabledEditor from '../Common/TableCellEditors/IsDisabledEditor';

import {
  DateTimeFormatter,
  NameFormatter,
  GeneratePDFButtonFormatter,
  EditableHeaderMarker,
  IsDisabledFormatter,
} from '../Common/TableCellFormatters';

import ObjectNameFilter from '../Common/Filters/ObjectNameFilter';
import ClientsEditor from '../Common/TableCellEditors/ClientsEditor';

const { NumericFilter, MultiSelectFilter } = Filters;

const defaultColumnProperties = {
  resizable: true,
};

const licensesColumns = [
    {
        key: 'licenseGroupId',
        name: 'licenses.columns.licenseGroupId',
        width: 100,
        visible: false,
    },
    {
        key: 'id',
        name: 'licenses.columns.id',
        width: 75,
        filterable: true,
        sortable: true,
        filterRenderer: NumericFilter,
    },
    {
        key: 'serialKey',
        name: 'licenses.columns.serialKey',
        width: 250,
        filterable: true,
        sortable: true,
    },
    {
        key: 'licenseType',
        name: 'licenses.columns.licenseType',
        width: 150,
        filterable: true,
        sortable: true,
        filterRenderer: MultiSelectFilter,
    },
    {
        key: 'status',
        name: 'licenses.columns.status',
        width: 150,
        filterable: true,
        sortable: true,
        filterRenderer: MultiSelectFilter,
      },
      {
        key: 'client',
        name: 'licenses.columns.client',
        width: 125,
        filterable: true,
        editable: true,
        formatter: NameFormatter,
        headerRenderer: <EditableHeaderMarker />,
        filterRenderer: ObjectNameFilter,
        editor: <ClientsEditor />
    },
    {
        key: 'reseller',
        name: 'licenses.columns.reseller',
        width: 200,
        filterable: true,
        editable: true,
        formatter: NameFormatter,
        headerRenderer: <EditableHeaderMarker />,
        filterRenderer: ObjectNameFilter,
    },
    {
        key: 'userGivenClient',
        name: 'licenses.columns.userGivenClient',
        width: 200,
        filterable: true,
        editable: false,
        formatter: NameFormatter,
        filterRenderer: ObjectNameFilter,
    },
    {
      key: 'note',
        name: 'licenses.columns.note',
        width: 200,
        filterable: true,
        editor: NoteEditor,
        editable: true,
        headerRenderer: <EditableHeaderMarker />,
    },
    {
        key: 'validUntil',
        name: 'licenses.columns.validUntil',
        width: 150,
        filterable: true,
        sortable: true,
        formatter: DateTimeFormatter,
        editor: ValidUntilEditor,
        editable: true,
        headerRenderer: <EditableHeaderMarker />,
    },
    {
      key: 'activationTimestamp',
        name: 'licenses.columns.activationTimestamp',
        width: 150,
        sortable: true,
        formatter: DateTimeFormatter,
    },
    {
        key: 'deactivationTimestamp',
        name: 'licenses.columns.deactivationTimestamp',
        width: 150,
        sortable: true,
        formatter: DateTimeFormatter,
      },
      {
        key: 'activationIp',
        name: 'licenses.columns.activationIp',
        width: 150,
      },
      {
        key: 'deactivationIp',
        name: 'licenses.columns.deactivationIp',
        width: 150,
      },
      {
          key: 'creator',
          name: 'licenses.columns.creator',
          width: 125,
          filterable: true,
          editable: false,
          formatter: NameFormatter,
          filterRenderer: ObjectNameFilter,
      },
      {
        key: 'isDisabled',
        name: 'licenses.columns.isDisabled',
        editor: IsDisabledEditor,
        formatter: IsDisabledFormatter,
        headerRenderer: <EditableHeaderMarker />,
        width: 150,
    },
    {
      key: 'licensePdfLangs',
      name: 'licenses.columns.generatePdf',
      formatter: GeneratePDFButtonFormatter,
      width: 150,
    },
].map(c => ({ ...c, ...defaultColumnProperties }));

export default licensesColumns;
