import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { createClient, updateClient } from '../../actions/clients';

export const CLIENT_MODAL_MODES = {
    ADD_CLIENT: 0,
    EDIT_CLIENT: 1,
};

class ClientModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.messageModal = withReactContent(Swal);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { mode: currentMode, client: currentClient } = this.props;
    const { mode: prevMode, client: prevClient } = prevProps;
    if (currentMode !== prevMode || currentClient !== prevClient) {
      this.setState({...this.defaultState()});
    }
  }

  defaultState = () => {
    const { mode, client } = this.props;

    switch (mode) {
      case CLIENT_MODAL_MODES.ADD_CLIENT:
        return {
          value: {},
        };
      case CLIENT_MODAL_MODES.EDIT_CLIENT:
        return {
          value: {
            name: client.name,
            email: client.email,
            organization: client.organization,
            phoneNumber: client.phoneNumber,
          },
        };
      default:
        return {
          value: {},
        };
    }
  };

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      value: {
          ...prevState.value,
          [target.name]: target.value
        }
    }));
  };

  constructEditJson = () => {
    const { value } = this.state;
    const editJson = [];

    Object.entries(value).forEach(([key, formValue]) => {
      if (formValue) {
        editJson.push({
          "op": "replace",
          "path": key,
          "value": formValue,
        })
      }
    });

    return editJson;
  };

  submit = () => {
    const { dispatch, mode, onClose, client } = this.props;
    const { value } = this.state;

    switch (mode) {
      case CLIENT_MODAL_MODES.ADD_CLIENT:
        dispatch(
            createClient({
              ...value,
            })
        ).then(() => {onClose()});
        break;
      case CLIENT_MODAL_MODES.EDIT_CLIENT:
        dispatch(
            updateClient(
              client.id,
              this.constructEditJson(),
            )
        ).then(onClose);
        break;
      default:
        break;
    }
  };

  renderForm = () => {
    const { t, onClose } = this.props;
    const { value: { name, email, organization, phoneNumber } } = this.state;

    return (
      <Form>
        <Form.Field
          required
          name="name"
          label={t(`licenses.clientModal.name`)}
          control='input'
          type='text'
          value={name || ''}
          onChange={this.handleChange}
        />
        <Form.Field
            name="email"
            label={t(`licenses.clientModal.email`)}
            control='input'
            type='email'
            value={email || ''}
            onChange={this.handleChange}
        />
        <Form.Field
            name="organization"
            label={t(`licenses.clientModal.organization`)}
            control='input'
            type='text'
            value={organization || ''}
            onChange={this.handleChange}
        />
        <Form.Field
            name="phoneNumber"
            label={t(`licenses.clientModal.phoneNumber`)}
            control='input'
            type='text'
            value={phoneNumber || ''}
            onChange={this.handleChange}
        />
        <Button onClick={this.submit}>{t(`licenses.clientModal.submit`)}</Button>
        <Button onClick={onClose}>{t(`licenses.clientModal.cancel`)}</Button>
      </Form>
    );
  };

  renderModal = (title, contentFunc) => {
      const { isModalOpen, onClose } = this.props;
      return (
        <Modal
          open={isModalOpen}
          onClose={onClose}
          closeIcon
        >
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content image scrolling>
            <Modal.Content>
              {contentFunc()}
            </Modal.Content>
          </Modal.Content>
        </Modal>
      )
  };

  render() {
    const { t, mode } = this.props;

    let title = '';
    switch(mode) {
        case CLIENT_MODAL_MODES.ADD_CLIENT:
            title = t(`licenses.clientModal.addClient`);
            break;
        case CLIENT_MODAL_MODES.EDIT_CLIENT:
            title = t(`licenses.clientModal.editClient`);
            break;
        default:
            title = '';
            break;
    }

    const contentFunc = this.renderForm;
    return (
      <>
        {this.renderModal(title, contentFunc)}
      </>
    );
  };
}

ClientModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mode: PropTypes.number.isRequired,
    client: PropTypes.object,
};

const mapStateToProps = state => ({
  clients: state.clients.items,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(ClientModal);
