import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import './index.css';

class IsDisabledEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newIsDisabled: props.value };
  }

  getValue = () => {
    const { newIsDisabled } = this.state;
    return { isDisabled: newIsDisabled };
  };

  getInputNode = () => {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  };

  handleDropdownChange = (event, data) => {
    this.setState({
      newIsDisabled: data.value,
    });
  };

  render () {
    const { choices } = this.props;
    const { newIsDisabled } = this.state;

    return (
        <div className="is-disabled-editor-container">
          <Dropdown
              name="isDisabled"
              options={choices}
              defaultValue={newIsDisabled}
              onChange={this.handleDropdownChange}
              placeholder="Choose"
              selection
              fluid
          />
       </div>
    );
  };
}

IsDisabledEditor.propTypes = {
  choices: PropTypes.array.isRequired,
};

export default IsDisabledEditor;
