import network from '../../utils/network';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';
import API_SETTINGS from '../../constants/api';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const login = data =>
  network.actionWrapper(
      `${API_SETTINGS.PREFIX}/auth/login`,
      {
          begin: ACTION_BEGIN,
          success: LOGIN_SUCCESS,
          failure: ACTION_FAILURE,
      },
      {
          method: 'POST',
          headers: new Headers({
              'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data),
      });

export const logout = () =>
    network.actionWrapper(
        `${API_SETTINGS.PREFIX}/auth/logout`,
        {
            begin: ACTION_BEGIN,
            success: LOGOUT_SUCCESS,
            failure: ACTION_FAILURE
        },
        { method: 'POST' }
    );
