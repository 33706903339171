import {
  FETCH_PROJECTS_SUCCESS,
} from '../../actions/projects';

const initialState = {
  items: [],
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      };

    default:
      return state;
  }
}
