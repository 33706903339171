import { ACTION_BEGIN,
  ACTION_FAILURE,
  HIDE_ERROR
} from '../../actions/app';
import { FETCH_PROJECTS_SUCCESS } from '../../actions/projects';
import {
  CREATE_LICENSES_SUCCESS,
  FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS,
  FETCH_LICENSES_FROM_GENERATION_SUCCESS,
  FETCH_LICENSES_FROM_PROJECT_SUCCESS,
  RESET_LICENSE_SUCCESS,
  UPDATE_LICENSE_SUCCESS,
} from '../../actions/licenses';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../../actions/auth';
import {
  CREATE_CLIENT_SUCCESS,
  FETCH_CLIENTS_SUCCESS,
  UPDATE_CLIENT_SUCCESS
} from '../../actions/clients';
import {
  FETCH_GENERATIONS_FROM_PROJECT_SUCCESS
} from '../../actions/generations';
import {
  FETCH_RESELLER_SUCCESS
} from '../../actions/resellers';

const initialState = {
  loading: false,
  errors: null,
  displayError: false,
  startedActionsCounter: 0,
};

export default function appReducer(state = initialState, action) {
  let actionsCounter = 0;
  let errors;

  switch (action.type) {
    case ACTION_BEGIN:
      return {
        ...state,
        loading: true,
        errors: null,
        displayError: false,
        startedActionsCounter: state.startedActionsCounter + 1
      };

    case FETCH_GENERATIONS_FROM_PROJECT_SUCCESS:
    case FETCH_LICENSES_FROM_PROJECT_SUCCESS:
    case FETCH_LICENSES_FROM_GENERATION_SUCCESS:
    case FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS:
    case FETCH_PROJECTS_SUCCESS:
    case CREATE_LICENSES_SUCCESS:
    case UPDATE_LICENSE_SUCCESS:
    case RESET_LICENSE_SUCCESS:
    case FETCH_CLIENTS_SUCCESS:
    case FETCH_RESELLER_SUCCESS:
    case CREATE_CLIENT_SUCCESS:
    case UPDATE_CLIENT_SUCCESS:
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      actionsCounter = state.startedActionsCounter - 1;

      return {
        ...state,
        loading: actionsCounter !== 0,
        startedActionsCounter: actionsCounter
      };

    case ACTION_FAILURE:
      actionsCounter = state.startedActionsCounter - 1;
      errors = action.payload.error.message.split(',');
      return {
        ...state,
        loading: actionsCounter !== 0,
        errors: Array.isArray(errors) ? errors : [errors],
        displayError: true,
        startedActionsCounter: actionsCounter
      };

    case HIDE_ERROR:
      return {
        ...state,
        displayError: false
      };

    default:
      return state;
  }
}

