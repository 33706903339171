import React from 'react';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import auth from '../../../utils/auth';
import roles from '../../../constants/roles';

import './index.css';

class LicenseEditToolbar extends React.Component {

  renderConfirmSaveModal = () => {
    const { t, handleSaveLicenseChanges } = this.props;

    Swal.fire({
      type: 'warning',
      title: t(`modal.title.confirm`),
      text: t(`modal.message.confirm.editLicenses`),
      confirmButtonText: t(`yes`),
      confirmButtonColor: '#21BA45',
      showCancelButton: true,
      cancelButtonText: t(`no`),
      cancelButtonColor: '#D33'
    })
      .then(result => {
        if (result.value) {
          handleSaveLicenseChanges();
        }
      })
  };

  renderConfirmRevertModal = () => {
    const { t, revertLicensesChanges } = this.props;

    Swal.fire({
      type: 'warning',
      title: t(`modal.title.confirm`),
      text: t(`modal.message.confirm.revertChanges`),
      confirmButtonText: t(`yes`),
      confirmButtonColor: '#21BA45',
      showCancelButton: true,
      cancelButtonText: t(`no`),
      cancelButtonColor: '#D33'
    })
      .then(result => {
        if (result.value) {
          revertLicensesChanges();
        }
      })
  };


  render() {
    const { t, isLicenseDataFetched, licenseChangeExists, licenseTableEditable, toggleTableEdit, onToggleFilter, removeSort } = this.props;
    return (
      <div className='edit-license-tools'>
        <Button.Group floated='left'>
          <Button
            className="green"
            icon='filter'
            labelPosition='left'
            content={t(`licenses.toolbar.filterRows`)}
            onClick={onToggleFilter}
          />
          <Button
            className="green"
            icon='refresh'
            labelPosition='left'
            content={t(`licenses.toolbar.unsort`)}
            onClick={removeSort}
          />
        </Button.Group>
        {auth.isRole([roles.ADMIN_ROLE, roles.EDIT_ROLE]) &&
          <Button.Group floated='right'>
            <Button
                className="green"
                disabled={!isLicenseDataFetched}
                active={licenseTableEditable}
                icon='edit'
                labelPosition='left'
                content={t(`licenses.toolbar.edit`)}
                onClick={toggleTableEdit}/>
            <Button
                className="green"
                disabled={!licenseChangeExists}
                icon='reply all'
                labelPosition='left'
                content={t(`licenses.toolbar.revertChanges`)}
                onClick={this.renderConfirmRevertModal}/>
            <Button
                className="green"
                disabled={!licenseChangeExists}
                icon='save'
                labelPosition='left'
                content={t(`licenses.toolbar.save`)}
                onClick={this.renderConfirmSaveModal}/>
          </Button.Group>
        }
      </div>
    );
  }
}

export default withTranslation('translation')(LicenseEditToolbar);