import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose} from 'redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { hideMessageModal } from '../../actions/app';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.messageModal = withReactContent(Swal);
  }

  closeMessageModal = () => {
    this.props.dispatch(hideMessageModal());
  };

  reloadPage = () => window.location.reload();

  renderMessageModal = (retryBtn, type, message) => {
    const { t } = this.props;

    this.messageModal.fire({
      type,
      html: message,
      onClose: this.closeMessageModal,
      confirmButtonColor: '#AF6098',
      showConfirmButton: retryBtn,
      confirmButtonText: t('retry'),
      showCancelButton: true,
      cancelButtonText: t('close'),
    })
        .then(result => {
          if (result.value) {
            this.reloadPage();
          }
        })
  };

  render() {
    const { isLoading, errors, displayError, t } = this.props;

    const messages = errors && errors.map(error => t([`error.${error}`, `error.genericError`]));
    const message = messages && messages.join('<br>');

    if (displayError) {
      this.renderMessageModal(false, 'error', message);
    }

    // TODO replace With loading component
    return (
      <div>
        {isLoading && "Loading..."}
      </div>
    );
  }
}

App.propTypes = {
  isLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  isLoading: state.app.loading,
  errors: state.app.errors,
  displayError: state.app.displayError,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(App);