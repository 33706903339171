function renderMessageModal(messageModal, message, confirmText) {
    messageModal.fire({
        type: 'error',
        text: message,
        confirmButtonColor: '#AF6098',
        showConfirmButton: true,
        confirmButtonText: confirmText,
    });
}

function getProjectName(projects, projectId) {
    const projectMatch = projects.filter(project => project.id.toString() === projectId);
    let projectName = null;

    if (projectMatch[0] !== undefined) {
        projectName = projectMatch[0].name;
    }
    return projectName;
}

export default {
   renderMessageModal,
   getProjectName,
};
