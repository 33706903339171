import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ClientModal, { CLIENT_MODAL_MODES } from '../ClientModal';
import { SearchableDropdown } from '../SearchableDropdown';

import './index.css';

class ClientsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.messageModal = withReactContent(Swal);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.newClient !== this.props.newClient) {
      const { clients, newClient } = this.props;
      const newClientValue = clients.find(c => c.id === newClient.id).id;

      this.setState(prevState => ({
        value: {
          ...prevState.value,
          clientId: newClientValue,
        }
      }));
    }
  };

  defaultState = () => ({
    value: {},
    isClientModalOpen: false,
    clientModalMode: CLIENT_MODAL_MODES.ADD_CLIENT,
  });

  onClose = () => {
    const { onClose } = this.props;
    this.setState(this.defaultState(), onClose);
  };

  handleDropdownChange = (event, data) => {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [data.name]: data.value,
      }
    }));
  };

  renderClientModal = () => {
    const { clients } = this.props;
    const { isClientModalOpen, clientModalMode, value: { clientId } } = this.state;

    const client = clients.find(c => c.id === clientId);

    return (
      <ClientModal
        isModalOpen={isClientModalOpen}
        onClose={() => this.setState({ isClientModalOpen: false })}
        mode={clientModalMode}
        client={client}
      />
    );
  };

  onAddNewClient = () => {
    this.setState({
      isClientModalOpen: true,
      clientModalMode: CLIENT_MODAL_MODES.ADD_CLIENT,
    });
  };

  onEditClient = () => {
    this.setState({
      isClientModalOpen: true,
      clientModalMode: CLIENT_MODAL_MODES.EDIT_CLIENT,
    });
  };

  renderForm = () => {
    const { t, clients } = this.props;
    const { value: { clientId } } = this.state;

    const clientsData = clients && clients.map(client => ({ key: client.id, value: client.id, text: client.name }));

    return (
      <Form name='licenseGenerationForm'>
        <Form.Field>
          <SearchableDropdown
            placeholder={t(`licenses.generation.chooseClient`)}
            items={clientsData}
            onSelectionChange={this.handleDropdownChange}
            name="clientId"
            value={clientId || ''}
            onAddNewClick={this.onAddNewClient}
            onEditClick={clientId ? this.onEditClient: ''}
          />
        </Form.Field>
      </Form>
    );
  };

  renderModal = (title, contentFunc) => {
    const { isModalOpen } = this.props;
    return (
      <Modal
        open={isModalOpen}
        onClose={this.onClose}
        closeIcon
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Content>
            {contentFunc()}
          </Modal.Content>
        </Modal.Content>
      </Modal>
    )
  };

  render() {
    const { t } = this.props;

    const title = t(`licenses.clients`);
    const contentFunc = this.renderForm;

    return (
      <>
        {this.renderClientModal()}
        {this.renderModal(title, contentFunc)}
      </>
    );
  };
}

ClientsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  clients: state.clients.items,
  newClient: state.clients.newClient,
});

export default compose(withTranslation('translation'), connect(mapStateToProps))(ClientsModal);
