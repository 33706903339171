import {
  CREATE_LICENSES_SUCCESS,
  RESET_LICENSE_SUCCESS,
  CLEAR_CREATED_LICENSES,
  FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS,
  FETCH_LICENSES_FROM_PROJECT_SUCCESS,
  FETCH_LICENSES_FROM_GENERATION_SUCCESS,
  UPDATE_LICENSE_SUCCESS,
} from '../../actions/licenses';

const initialState = {
  licenses: [],
};

function licenseTypesSelector(licenseTypes) {
  return licenseTypes.map(licenseType => {
    return {
      text: licenseType.name,
      value: licenseType.id,
      number: licenseType.licensesNum,
    };
  });
}

function createLicensesSelector(licenses) {
    const firstLicense = licenses[0];

    const generalInfo = {
      licenseType: firstLicense.licenseType,
      validUntil: firstLicense.validUntil,
      client: firstLicense.client,
      reseller: firstLicense.reseller,
      note: firstLicense.note,
    };

    const serialKeys = licenses.map(license => license.serialKey);

    return {
      generalInfo,
      serialKeys,
    };
}

export default function licensesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LICENSES_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        licenses: action.payload.data,
      };
    case FETCH_LICENSES_FROM_GENERATION_SUCCESS:
      return {
        ...state,
        licenses: action.payload.data,
      };
    case FETCH_LICENSE_TYPES_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        licenseTypes: licenseTypesSelector(action.payload.data),
      };
    case CREATE_LICENSES_SUCCESS:
      return {
        ...state,
        createdLicenses: createLicensesSelector(action.payload.data),
        newLicenses: action.payload.data,
      };
    case UPDATE_LICENSE_SUCCESS:
      return {
        ...state,
      };
    case RESET_LICENSE_SUCCESS:
      return {
        ...state,
        message: action.payload.data.status,
      };
    case CLEAR_CREATED_LICENSES:
      return {
        ...state,
        createdLicenses: null,
      };
    default:
      return state;
  }
}
