import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Segment, Divider } from 'semantic-ui-react';

import { fetchProjects } from '../../actions/projects';
import PROJECT_COLORS from './projectColors';

import './index.css';

export class ProjectsScreen extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchProjects());
  }

  renderCard = ({ id, name }) => (
    <Link
      className="project-link"
      key={id}
      to={`/licenses/${id}`}
    >
      <Card
        link
        key={id}
        header={name}
        color={PROJECT_COLORS[id % PROJECT_COLORS.length]}
      />
    </Link>
  );

  render() {
    const { projects } = this.props;

    return  (
      <Segment>
        <Divider />
        <Card.Group centered className="test">
          {projects.map(this.renderCard)}
        </Card.Group>
      </Segment>
    );
  }
}


const mapStateToProps = state => ({
  projects: state.projects.items,
  loading: state.app.loading,
  error: state.app.error,
});


export default connect(mapStateToProps)(ProjectsScreen);
