import React from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import './index.css';

function TableToolbar ({ onToggleFilter }) {
    const { t } = useTranslation();
    return (
      <div className='table-toolbar'>
        <Button.Group floated='left'>
          <Button
            className="green"
            icon='filter'
            labelPosition='left'
            content={t(`licenses.toolbar.filterRows`)}
            onClick={onToggleFilter}
          />
        </Button.Group>
      </div>
    );
  }

export default TableToolbar;