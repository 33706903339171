import config from '../config';

async function handleErrors(response) {
  if (!response.ok) {
    let content = response.statusText;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      content = await response.json();
      throw Error(content.message);
    } else {
      content = await response.text();
      throw Error(content);
    }
  }
  return response;
}

function fetchBegin(type) {
  return {
    type
  };
}

function fetchSuccess(type, data) {
  return {
    type,
    payload: { data }
  };
}

function fetchError(type, error) {
  return {
    type,
    payload: { error }
  };
}

function actionWrapper(path, constants = {}, options = {}) {
  const DEFAULTS = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  };

  options = { ...DEFAULTS, ...options }; // eslint-disable-line no-param-reassign

  return dispatch => {
    dispatch(fetchBegin(constants.begin));
    return fetch(`${config.apiUrl}/${path}`, options)
    .then(handleErrors)
    .then(res => {
      const contentType = res.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return res.json();
      }
      return res.text();

    })
    .then(res => {
      dispatch(fetchSuccess(constants.success, res));
      return res;
    })
    .catch(error => dispatch(fetchError(constants.failure, error)));
  };
}

export default {
  actionWrapper,
};
