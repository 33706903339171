export const ACTION_BEGIN = 'ACTION_BEGIN';
export const ACTION_FAILURE = 'ACTION_FAILURE';

export const HIDE_ERROR = 'HIDE_ERROR';

export function hideMessageModal() {
  return dispatch => {
    dispatch(({
      type: HIDE_ERROR,
    }));
  };
}