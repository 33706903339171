import { USER_ROLE_KEY } from '../constants/roles';

const AUTH_EXP = 'authExp';

function isAuthed() {
    const authExp = localStorage.getItem(AUTH_EXP);
    return !!authExp && authExp > (Date.now() / 1000);
}

function isRole(allowedRoles) {
  const userRole = localStorage.getItem(USER_ROLE_KEY);
  return !!userRole && allowedRoles.includes(userRole);
}

function saveData({ validUntil, role } = {}) {
    localStorage.setItem(AUTH_EXP, validUntil);
    localStorage.setItem(USER_ROLE_KEY, role);
}

function removeData() {
    localStorage.removeItem(AUTH_EXP);
    localStorage.removeItem(USER_ROLE_KEY);
}

export default {
    isAuthed,
    isRole,
    saveData,
    removeData,
};
