import React from 'react';
import ReactDOM from 'react-dom';
import ClientsDropdown from '../../../ClientsDropdown';

class ClientsEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedClient: props.value };
    }

    getValue() {
        const { selectedClient } = this.state;
        return { client: selectedClient };
    }

    getInputNode() {
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }

    handleDropdownChange = (event, data) => {
        this.setState({ selectedClient: data.value }, () => this.props.onCommit());
    };

    render() {
        return (
          <ClientsDropdown onSelectionChange={this.handleDropdownChange} />
        );
    };
}

export default ClientsEditor;